import React from "react";
import formimage from "../public/static/images/formimage.svg";
import formcircles from "../public/static/images/formcircles.svg";
import Image from "next/image";

const Formimage = () => {
  return (
    <div className="position-relative id">
      <div className="img_box id">
        <Image className="p-5" src={formimage} alt="circle image"/>
      </div>
      
      {/* <div className="formcircle">
        <Image src={formcircles} alt="circle image" />
      </div>
        <div className="background-image">
          <Image src={formimage} alt="login image" />
        </div> */}
    </div>
  );
};

export default Formimage;
