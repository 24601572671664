'use client'
import Link from "next/link";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import Formimage from "../../components/Formimage";
import { FiLock } from "react-icons/fi";
import { TfiEmail } from "react-icons/tfi";
import Image from "next/image";
import googlelogo from '../../public/static/images/googlelogo.svg';
import microsoftlogo from '../../public/static/images/microsoftlogo.svg';
import {useDispatch,useSelector} from "react-redux"
import {setUser} from '../reduxStore/Reducers/authReducer';
import { useUser } from '@auth0/nextjs-auth0/client';
import {useEffect} from "react"



export default function SignIn() {
  const { user, error, isLoading } = useUser();
  const dispatch=useDispatch();
  const userData=useSelector((state)=>state.reducer.auth.user);
  
  useEffect(()=>{ 
    if(user&&!userData) dispatch(setUser(user))
  },[user])
  return (
    <section className="sign-in">
      <Container>
        <Row className="d-flex align-items-center justify-content-md-center">
          <Col lg={5} md={10} sm={12}>
            <div className="text pt-5">Sign In</div>
            <h2 className="m-0">
              Get Exclusive access to <strong>COGNOGENT</strong>
            </h2>
            <Form className="pt-4">
              <div className="position-relative mb-3">
                <TfiEmail className="form-icon"/>
              <Form.Label>Email Id</Form.Label>
              <Form.Control type="email" placeholder="Email" />
              </div>
              <div className="position-relative">
                <FiLock className="form-icon"/>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
              </div>
              <Form.Check
                type="checkbox"
                label="Remember me"
                className="pb-3 mt-3"
                id="1"
              />
              <div className="d-flex align-items-center justify-content-between">
                <Button>
                  <span className="d_aic_jcb">
                    Continue
                    <FaArrowRight />
                  </span>
                </Button>
                <Link href="#" className="forget-password">
                  Forget password?
                </Link>
              </div>
              <div className="w-100 d_aic_jcc py-4">
                {" "}
                <p className="or m-0">or</p>
              </div>

              <Row>
                <Col>
                    <div  className="socialmedia d-flex align-itemes-center justify-content-center gap-2">
                      <div className="google-icon">
                        <Image src={microsoftlogo} alt="google icon"/>
                      </div>
                      <Link href='/api/auth/login'><h3 className="m-0 sign">Sign up with Google</h3></Link>

                    </div>
                </Col>
                <Col>
                    <div className="socialmedia d-flex align-itemes-center justify-content-center gap-2">
                      <div className="google-icon">
                        <Image
                          src={googlelogo}
                          alt="google icon"
                        />
                      </div>
                      <h3 className="m-0 sign">Sign up with Microsoft</h3>
                    </div>
                </Col>
                <div className="sign text-center py-3">
                  <Link href="/signup">
                    <h3>
                      <span> Don’t have an account ? </span>Sign up
                    </h3>
                  </Link>
                </div>
              </Row>
            </Form>
          </Col>
          <Col
            lg={7}
            className="d-none d-lg-block"
          >
            <Formimage/>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

